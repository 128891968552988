import { FC } from "react";
import Page from "src/templates/Page";
import { graphql, PageProps } from "gatsby";

const NotFoundPage: FC<PageProps<Gatsby.Prismic404PageQuery>> = (props) => (
  <Page {...props} />
);

export default NotFoundPage;

export const query = graphql`
  query Prismic404Page {
    prismicPage(uid: { eq: "not-found" }) {
      _previewable
      type
      uid

      data {
        indexed
        meta_description
        meta_title
        social_media_thumbnail {
          url
        }
        site_footer_override {
          document {
            ...SiteFooter
          }
        }
        social_media_title

        body {
          __typename
          ...PrismicSliceMetadata

          ...PageHeader
          ...PageHeaderSpotlight
          ...PageBlockColumns
          ...PageBlockCta
          ...PageBlockRelation
          ...PageBlockAccordion
          ...PageBlockFaq
          ...PageBlockCardsRedirection
          ...PageBlockMatrix
          ...PageBlockProductRecap
          ...PageBlockTestimonial
          ...PageBlockHighlightCards
          ...PageBlockTestimonialCarousel
          ...PagePricingPlans
          ...PageBlockPricingDisplay
          ...PageBlockSteps
          ...PageBlockBanner
          ...PageBlockVideo
          ...PageBlockPlansPresentation
          ...PageBlockCreationPackagePricing
          ...PageBlockPricingTable
          ...PageSubPages
          ...PageBlockTitle
          ...PageBlockSecurity
          ...PageBlockPersona
          ...PageBlockMission
          ...PageBlockCreationRedirection
          ...PageBlockTrioProductHighlight
          ...PageBlockTetrisProductHighlight
          ...PageBlockFeatureCarousel
          ...PageBlockCenter
          ...PageBlockAnimatedTestimonial
          ...PageBlockNumber
          ...PageBlockHubspotForm
          ...PageBlockEmbedHubspotForm
          ...PageBlockFilterableList
          ...PageBlockTrioOffer
          ...PageBlockHoverableCards
          ...PageBlockScrollableCards
          ...PageBlockStretchableCards
          ...PageBlockAnimation
          ...PageBlockPricingLight
          ...PageBlockProofHeadband
          ...PageBlockUSP
        }
      }
    }
  }
`;
